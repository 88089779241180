import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      isActive: (state) => state.global.mainSidebar,
      isLoggedIn: (state) => state.global.loggedIn,
      user: (state) => state.global.user,
      baseUrl: (state) => state.global.baseUrl,
      mainSidebar: (state) => state.global.mainSidebar,
    }),
  },
  methods: {
    showLogin() {
      this.$modal.show('modal--login');
      if (this.mainSidebar === true)
        this.$store.commit('global/SET_MAIN_SIDEBAR', !this.mainSidebar);
    },
    showRegister() {
      this.$modal.show('modal--register');
      if (this.mainSidebar === true)
        this.$store.commit('global/SET_MAIN_SIDEBAR', !this.mainSidebar);
    },
    setSidebar() {
      this.$store.commit('global/SET_MAIN_SIDEBAR', !this.mainSidebar);
      this.$store.commit('global/SET_SIDEBAR_CONTENT', null);
    },
    async logout() {
      await this.$store.dispatch('global/logout');
      this.$router.go(0);
    },
  },
};
